import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { post } from "api/requests";
import { del, get, put } from "api/requests";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useState } from "react";

const getListCourse = async (params) => await get("course/list", params);
const getListCourseCurrentYear = async (params) =>
  await post("course/list-current-year", params);
const detailCourse = (id) => get(`course/detail/${id}`);
const createCourse = (payload) => post("course/create", payload);
const updateCourse = (payload) => post(`course/update/${payload.id}`, payload);
const deleteCourse = (id) => del(`course/delete/${id}`);
const pushJobManual = (payload) =>
  post(`course/manual-push/${payload.id}`, payload);

export const usePushJobManual = (param = {}) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation(
    (payload) => {
      payload.employee_id = JSON.parse(localStorage.getItem("auth_user")).id;
      return pushJobManual(payload);
    },
    {
      onSuccess: (response) => {
        toast.success(t("pages.course_form.push_success"));
        if (param.isAssign) {
          // queryClient.invalidateQueries(['getCourse']);
        } else if (param.isPersonal) {
          queryClient.invalidateQueries(['getCourse']);
        } else {
          queryClient.invalidateQueries("course/detail");
          history.push(`/admin/courses/detail/${response.result?.data}`);
        }
      },
      onError: () => {
        toast.error("進捗失敗");
      },
    }
  );
};

export const useGetListCourse = (param = {}) => {
  return useQuery(["course/list", param], () => getListCourse(param), {
    enabled: true,
  });
};

export const useGetListCourseCurrentYear = () => {
  const defaultPaginate = {
    page: 1,
    limit: 3,
  };
  const [paramsListCourse, setParamsListCourse] = useState({
    ...defaultPaginate,
    document_name: null,
  });

  const handleSearch = (documentName) => {
    setParamsListCourse({
      ...defaultPaginate,
      document_name: documentName,
    });
  };

  const query = useQuery(
    ["course/list-current-year", paramsListCourse],
    async () => {
      const res = await getListCourseCurrentYear(paramsListCourse);

      return res?.result;
    },
    {
      enabled: true,
    }
  );

  const refetchListCourse = () => {
    setParamsListCourse(defaultPaginate);
    query.refetch(defaultPaginate);
  };

  return {
    paramsListCourse,
    setParamsListCourse,
    handleSearch,
    refetchListCourse,
    ...query,
    isLoading: query.isLoading || query.isFetching,
  };
};

export const getDetailCourse = (id, enabled) => {
  return useQuery(["getCourse", id], () => detailCourse(id), {
    enabled: !!id && !!enabled,
  });
};

export const useCreateCourse = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation((payload) => createCourse(payload), {
    onSuccess: () => {
      toast.success(t("pages.course_form.created_success"));
      queryClient.invalidateQueries("course/list");
      history.push("/admin/courses");
    },
    onError: () => {
      toast.error(t("pages.course_form.failed"));
    },
  });
};

export const useUpdateCourse = () => {
  const queryClient = useQueryClient();

  return useMutation((payload) => updateCourse(payload), {
    onSuccess: () => {
      toast.success(t("pages.course_form.updated_success"));
      queryClient.invalidateQueries("course/list");
    },
    onError: () => {
      toast.error(t("pages.course_form.failed"));
    },
  });
};

export const useDeleteCourse = (isPersonal = false) => {
  const history = useHistory();
  return useMutation((id) => deleteCourse(id), {
    onSuccess: () => {
      toast.success(t("pages.course_form.delete_success"));
      if (isPersonal) {
        history.push("/admin/individual-training");
      } else {
        history.push("/admin/courses");
      }
    },
    onError: (err) => {
      toast.error(t("pages.course_form.failed"));
    },
  });
};
