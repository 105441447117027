import {
  Box,
  Checkbox,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import Pagination from "components/Pagination/Pagination";
import { t } from "i18next";
import React from "react";

const SearchEmployeeResult = (props) => {
  const {
    dataEmployees,
    checkAllCondition,
    isLoading,
    checkSelected,
    handleCheckboxChange,
    onChangeSelectAll,
    paramsListEmployee,
    setParamsListEmployee,
    pagination,
    setPagination,
    totalCount,
    maxHeigh,
  } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const getListPaginate = () => {
    if (!paramsListEmployee.isPaging && dataEmployees) {
      const offset = (pagination.page - 1) * pagination.limit;
      return dataEmployees.slice(offset, pagination.limit + offset);
    }

    return dataEmployees;
  };

  const getPagingParams = (key) => {
    switch (key) {
      case "showCondition":
        return paramsListEmployee.isPaging
          ? Math.ceil(totalCount / paramsListEmployee.limit) > 1
          : Math.ceil(dataEmployees?.length / pagination.limit) > 1;
      case "total":
        return paramsListEmployee.isPaging ? totalCount : dataEmployees?.length;
      case "limit":
        return paramsListEmployee.isPaging
          ? paramsListEmployee.limit
          : pagination.limit;
      case "page":
        return paramsListEmployee.isPaging
          ? paramsListEmployee.page
          : pagination.page;
      case "setFunc":
        return paramsListEmployee.isPaging
          ? setParamsListEmployee
          : setPagination;
    }
  };

  return (
    <>
      <Box>
        <Table variant="simple" color={textColor} marginTop={"20px"} size="xsm">
          <Thead position="sticky" top={0}>
            <Tr my=".8rem" pl="0px">
              <Th borderColor={borderColor} width={"1%"} />
              <Th borderColor={borderColor} width={"20%"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.member_name")}
                </Text>
              </Th>
              <Th borderColor={borderColor} width={"10%"}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.hiring_date")}
                </Text>
              </Th>
              <Th borderColor={borderColor} width={"10%"}>
                <Text fontSize={"14px"}>{t("pages.course_form.position")}</Text>
              </Th>
              <Th borderColor={borderColor}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.job_title")}
                </Text>
              </Th>
              <Th borderColor={borderColor}>
                <Text fontSize={"14px"}>
                  {t("pages.course_form.affiliation")}
                </Text>
              </Th>
              <Th borderColor={borderColor} alignItems={"right"} width={"17%"}>
                <Box display={"flex"} justifyContent="end" pr="5px">
                  <Text fontSize={"14px"} pr={"5px"}>
                    {t("pages.course_form.select_all")}
                  </Text>
                  <Checkbox
                    colorScheme="green"
                    isChecked={checkAllCondition()}
                    disabled={isLoading}
                    onChange={(e) => {
                      onChangeSelectAll(e.target.checked);
                    }}
                  />
                </Box>
              </Th>
            </Tr>
          </Thead>
        </Table>
        <Box overflowY="auto" maxHeight={maxHeigh}>
          <Table
            variant="simple"
            color={textColor}
            marginTop={"20px"}
            size="xsm"
          >
            <Tbody>
              {getListPaginate()?.length ? getListPaginate()?.map((item, index) => {
                return (
                  <Tr key={index}>
                    <Td width={"1%"}>
                      <Text fontSize={"14px"} pb=".5rem">
                        {index + 1}
                      </Text>
                    </Td>
                    <Td width={"20%"}>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.name}
                      </Text>
                    </Td>
                    <Td width={"10%"}>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.join_date}
                      </Text>
                    </Td>
                    <Td width={"10%"}>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.work_status}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.job}
                      </Text>
                    </Td>
                    <Td>
                      <Text fontSize={"14px"} pb=".5rem">
                        {item.department}
                      </Text>
                    </Td>
                    <Td width={"17%"} textAlign={"right"} pr="5px">
                      <Text fontSize={"14px"} pb=".5rem"></Text>
                      <Checkbox
                        colorScheme="green"
                        isChecked={checkSelected(item)}
                        onChange={(event) => handleCheckboxChange(event, item)}
                      />
                    </Td>
                  </Tr>
                );
              }) :
                <div style={{ textAlign: "center" }}>
                  <p>{t("messages.common_s003")}</p>
                </div>
              }
            </Tbody>
          </Table>
        </Box>
      </Box>

      {isLoading && (
        <Box textAlign="center" mt="40px">
          <Spinner></Spinner>
        </Box>
      )}
      <Box m="20px 0">
        {getPagingParams("showCondition") && (
          <Pagination
            total={getPagingParams("total")}
            perPage={getPagingParams("limit")}
            currentPage={getPagingParams("page")}
            setParams={getPagingParams("setFunc")}
          />
        )}
      </Box>
    </>
  );
};

export default SearchEmployeeResult;
