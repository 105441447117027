import {
  Box,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Text,
  Spinner,
  Input,
} from "@chakra-ui/react";
import PrimaryButton from "components/Buttons/PrimaryButton";
import Pagination from "components/Pagination/Pagination";
import { t } from "i18next";
import React, { useMemo, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ListCourse = (props) => {
  const {
    responseListCourse,
    isLoading,
    paramsListCourse,
    setParamsListCourse,
    handleSearchCourse,
  } = props;
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const inputRef = useRef(null);

  const pagingData = useMemo(
    () => ({
      total: responseListCourse?.meta?.total ?? 0,
      perPage: responseListCourse?.meta?.per_page ?? 0,
      currentPage: responseListCourse?.meta?.current_page ?? 0,
    }),
    [JSON.stringify(responseListCourse)]
  );

  const handleSearch = () => {
    handleSearchCourse(inputRef?.current?.value ?? null);
  };

  const setParams = (data) => {
    const newPage = data()?.page ?? 0;
    setParamsListCourse({ ...paramsListCourse, page: newPage });
  };

  return (
    <>
      <Box>
        <Grid
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: `repeat(3, 1fr)`,
          }}
          gap="10px"
        >
          <GridItem colSpan={2}>
            <Flex pl="50px">
              <Box w={"100px"} textAlign={"right"} marginRight={"5px"}>
                <Text fontSize={"14px"}>
                  {t("pages.random_assign_course.training_course_name")}
                </Text>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Input
                  style={{ height: "30px", fontSize: "14px" }}
                  ref={inputRef}
                />
              </Box>
              <Box ml="10px">
                <PrimaryButton
                  text={t("common.search")}
                  onClick={handleSearch}
                />
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
      <Table variant="simple" color={textColor} marginTop={"20px"} size="xsm">
        <Thead>
          <Tr my=".8rem" pl="0px">
            <Th borderColor={borderColor} width={"10%"} />
            <Th borderColor={borderColor} width={"80%"}>
              <Text fontSize={"14px"}>
                {t("pages.random_assign_course.training_course_name")}
              </Text>
            </Th>
            <Th borderColor={borderColor}>
              <Text fontSize={"14px"} textAlign="center">
                {t("pages.random_assign_course.detail")}
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && (
            <Tr>
              <Td colSpan={3} textAlign="center" p="30px 0">
                <Spinner></Spinner>
              </Td>
            </Tr>
          )}
          {!isLoading && responseListCourse?.data?.map((course, index) => {
            return (
              <Tr key={index}>
                <Td width={"10%"}>
                  <Text fontSize={"14px"} pb=".5rem" textAlign="center">
                    {index + 1}
                  </Text>
                </Td>
                <Td width={"80%"}>
                  <Text fontSize={"14px"} pb=".5rem" fontWeight="bold">
                    {course?.document_name}
                  </Text>
                </Td>
                <Td textAlign="center">
                  <PrimaryButton
                    text={t("pages.courses.table.detail_button")}
                    onClick={() =>
                      // history.push(`/admin/individual/courses/detail/${course.id}`)
                      history.push(`/admin/individual/courses/edit/${course.id}`)
                    }
                    size={"xs"}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {!isLoading && Math.ceil(pagingData.total / pagingData.perPage) > 1 && (
        <Pagination
          total={pagingData.total}
          perPage={pagingData.perPage}
          currentPage={pagingData.currentPage}
          setParams={setParams}
        />
      )}
    </>
  );
};

export default ListCourse;
