import React, { useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { t } from "i18next";

// Components
import Card from "components/Card/Card";
import SearchEmployeeForm from "components/SearchEmployee/SearchEmployeeForm";
import SearchEmployeeResult from "components/SearchEmployee/SearchEmployeeResult";
import CardHeader from "components/Card/CardHeader";
import DisableButton from "components/Buttons/DisableButton";
import GreenButton from "components/Buttons/GreenButton";

// Hooks
import useSearchEmployee from "hooks/useSearchEmployee";
import { useMasterData } from "hooks/useMasterData";
import { getDetailCourse } from "hooks/useCourse";

// Constants and Utils
import {
  FIELD_SEARCH_EMPLOYEE_CODE,
  FIELD_SEARCH_EMPLOYEE_NAME,
  FIELD_SEARCH_EMPLOYEE_WORK_STATUS,
  FIELD_SEARCH_EMPLOYEE_TYPE,
  FIELD_SEARCH_EMPLOYEE_HIRING_DATE,
  FIELD_SEARCH_EMPLOYEE_ROLE_1,
  FIELD_SEARCH_EMPLOYEE_ROLE_2,
  FIELD_SEARCH_EMPLOYEE_DEPARTMENT,
  FIELD_SEARCH_EMPLOYEE_BUSSINESS,
  FIELD_SEARCH_EMPLOYEE_POSITION,
  FIELD_SEARCH_EMPLOYEE_JOB,
} from "constants";
import { customSearchEmployeeInput } from "utils";
import { useRandomAssignCourse } from "hooks/useEmployee";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { usePushJobManual } from "hooks/useCourse";

const FORM_KEYS = [
  "name",
  "from",
  "to",
  "work_status",
  "role_1",
  "job_code",
  "role_2",
  "employment_type_code",
  "code",
  "department_2_code",
  "department_3_code",
  "department_4_code",
  "department_5_code",
  "employee_group",
];

const IndividualTrainingAssignEmployee = () => {
  const { courseId } = useParams();
  const location = useLocation();

  const schema = yup.object().shape({
    from: yup.string().nullable(),
    to: yup
      .string()
      .nullable()
      .test(
        "error",
        t("messages.date_end"),
        (value, context) =>
          !(value && new Date(value) < new Date(context.parent.from))
      ),
  });

  const { data: dataCourse } = getDetailCourse(courseId, true);
  const { data: masterData } = useMasterData();
  const mutationPushJobManual = usePushJobManual({isAssign: true, courseId: courseId});

  const {
    paramsListEmployee,
    onSubmit,
    setParamsListEmployee,
    pagination,
    setPagination,
    onChangeSelectAll,
    dataEmployees,
    isLoading,
    checkAllCondition,
    checkSelected,
    handleCheckboxChange,
    setSelectedList,
    selectedListState,
    setSelectedListState,
  } = useSearchEmployee();

  const methods = useForm({
    defaultValues: FORM_KEYS.reduce(
      (acc, key) => ({ ...acc, [key]: null }),
      {}
    ),
    resolver: yupResolver(schema),
  });

  const submitAndClose = () => {
    // clear data selection search
    FORM_KEYS.map((formKey) => {
      methods.setValue(formKey, "");
    });

    onSubmit({});
    setSelectedList(selectedListState);
  };

  const handleRandomAssign = () => {
    mutationPushJobManual.mutate({
      user_ids: selectedListState.map((item) => item.id),
      id: courseId,
    });
  };

  useEffect(() => {
    const detailCourse = dataCourse?.result?.data;
    if (detailCourse) {
      const selectedUser = JSON.parse(detailCourse?.user_assigned_list || "[]");
      const selectedIds = JSON.stringify(selectedUser.map((user) => user.id));
      setSelectedList(selectedUser);

      setParamsListEmployee((prev) => ({
        ...prev,
        id_selected_list: detailCourse?.is_required_all ? null : selectedIds,
        id_exception_list: detailCourse?.is_required_all ? selectedIds : null,
        no_data: 0,
        isPaging: detailCourse?.is_required_all ? 1 : 0,
      }));
    }
  }, [dataCourse]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "40px" }}>
      <Card>
        <CardHeader p="6px 0px 20px 0px">
          <Text fontSize="xl" fontWeight="bold">
            {t("pages.individual.individual_assign_employee")}
          </Text>
        </CardHeader>
        <SearchEmployeeForm
          methods={methods}
          inputList={[
            FIELD_SEARCH_EMPLOYEE_CODE,
            FIELD_SEARCH_EMPLOYEE_NAME,
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_JOB, {
              data: [{ id: "", name: "" }, ...(masterData?.jobs || [])],
            }),
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_TYPE, {
              data: [
                { id: "", name: "" },
                ...(masterData?.employment_types || []),
              ],
            }),
            FIELD_SEARCH_EMPLOYEE_HIRING_DATE,
            FIELD_SEARCH_EMPLOYEE_ROLE_1,
            FIELD_SEARCH_EMPLOYEE_ROLE_2,
            FIELD_SEARCH_EMPLOYEE_DEPARTMENT,
            FIELD_SEARCH_EMPLOYEE_BUSSINESS,
            FIELD_SEARCH_EMPLOYEE_WORK_STATUS,
            customSearchEmployeeInput(FIELD_SEARCH_EMPLOYEE_POSITION, {
              data: [{ id: "", name: "" }, ...(masterData?.positions || [])],
            }),
          ]}
          col={3}
          submitAndClose={submitAndClose}
          handleSubmit={methods.handleSubmit(onSubmit)}
          isModal
        />
        <SearchEmployeeResult
          isLoading={isLoading}
          dataEmployees={dataEmployees}
          paramsListEmployee={paramsListEmployee}
          setParamsListEmployee={setParamsListEmployee}
          pagination={pagination}
          setPagination={setPagination}
          checkAllCondition={checkAllCondition}
          checkSelected={checkSelected}
          handleCheckboxChange={handleCheckboxChange}
          onChangeSelectAll={onChangeSelectAll}
          totalCount={dataEmployees?.result?.meta?.total}
          maxHeigh="full"
        />
        <Flex justifyContent="center" pb="20px">
          {(dataCourse?.result?.data?.campus_status === 2 || mutationPushJobManual.isSuccess) ? (
            <DisableButton text={t("pages.course_form.pushing")} isLoading />
          ) : (
            <GreenButton
              text={t("pages.course_form.cloud_campus_status_1")}
              onClick={handleRandomAssign}
              disabled={dataCourse?.result?.data?.campus_status === 4}
            />
          )}
        </Flex>
      </Card>
    </Flex>
  );
};

export default IndividualTrainingAssignEmployee;
