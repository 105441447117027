import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  RadioGroup,
  Stack,
  Radio,
  Text
} from '@chakra-ui/react'
import { t } from 'i18next'
import React from 'react'
import { Controller } from 'react-hook-form'

const ERadioGroup = ({
  label,
  name,
  control,
  radioData,
  direction = 'row',
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl isInvalid={!!error} width={'auto'}>
          {label && <FormLabel>{label}</FormLabel>}
          <RadioGroup {...field}>
            <Stack direction={direction} gap={'20px'}>
              {radioData.map((item, index) => (
                <Radio value={item.id} key={item.id}>
                  <Text fontSize={'14px'}>
                    {t(item.value)}
                  </Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
          <FormErrorMessage fontSize={'14px'}>{error && error.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  )
}

export default ERadioGroup
