import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get, post } from "api/requests";
import { t } from "i18next";
import { toast } from "react-toastify";

const getListEmployee = async (params) => await post("get-employee", params);
const getListAdmin = async (params) => await get("get-admin-list", params);
const updateEmployee = async (id) => await post(`update-employee/${id}`);
const getListEmployeeByCourseId = async (id, params) =>
  await get(`get-employee-course/${id}`, params);
const randomAssignCourse = async (params) =>
  await post("employee/random-assign-personal-course", params);

export const useGetListEmployee = (params = {}) => {
  return useQuery(["get-employee", params], () => getListEmployee(params), {
    enable: true,
  });
};

export const useGetListAdmin = (params = {}) => {
  return useQuery(["get-admin-list", params], () => getListAdmin(params), {
    enable: true,
  });
};

export const useGetEmployeeByCourseId = (params = {}, id) => {
  return useQuery(
    ["get-employee-course", params, id],
    () => getListEmployeeByCourseId(id, params),
    {
      enable: true,
    }
  );
};

export const useUpdateEmployee = (id) => {
  const queryClient = useQueryClient();
  return useMutation((id) => updateEmployee(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("get-employee");
      queryClient.invalidateQueries("get-admin-list");
    },
  });
};

export const useRandomAssignCourse = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload) => {
      return randomAssignCourse(payload);
    },
    {
      onSuccess: (_res, variables) => {
        toast.success(t("messages.random_assign_success"));
        queryClient.invalidateQueries("course/list-current-year");

        if (variables.callback) {
          variables.callback();
        }
      },
      onError: () => {
        toast.error(t("messages.server_error"));
      },
    }
  );
};
