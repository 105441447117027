/*eslint-disable*/
import { ArrowBackIcon, HamburgerIcon } from '@chakra-ui/icons'
// chakra imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import IconBox from 'components/Icons/IconBox'
import { RocketIcon } from 'components/Icons/Icons'
import {
  renderThumbDark,
  renderThumbLight,
  renderTrack,
  renderTrackRTL,
  renderView,
  renderViewRTL
} from 'components/Scrollbar/Scrollbar'
import { HSeparator } from 'components/Separator/Separator'
import { STORAGE } from 'constants'
import { t } from 'i18next'
import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { NavLink, useLocation } from 'react-router-dom'
import { getInformation, removeCookie, getCookie } from 'utils'
import iconLogOut from 'assets/img/log-out.png';

// SIDEBAR

function Sidebar(props) {
  // to check for active links and opened collapses
  let location = useLocation()
  // this is for the rest of the collapses
  const [state, setState] = React.useState({})
  const mainPanel = React.useRef()
  let variantChange = '0.2s linear'
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : ''
  }
  const { colorMode } = useColorMode
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const { sidebarVariant } = props
  const createLinks = (routes) => {
    // Chakra Color Mode
    let activeBg = useColorModeValue('white', 'navy.700')
    let inactiveBg = useColorModeValue('white', 'navy.700')
    let activeColor = useColorModeValue('gray.700', 'white')
    let inactiveColor = useColorModeValue('gray.400', 'gray.400')
    let sidebarActiveShadow = '0px 7px 11px rgba(0, 0, 0, 0.04)'
    return routes.map((prop, key) => {
      if (prop.redirect || prop.hideMenu) {
        return null
      }
      if (prop.category) {
        var st = {}
        st[prop['state']] = !state[prop.state]
        return (
          <Box>
            <Text
              color={activeColor}
              fontWeight='bold'
              mb={{
                xl: '6px'
              }}
              mx='auto'
              ps={{
                sm: '10px',
                xl: '16px'
              }}
              py='12px'
            >
              {t(prop.name)}
            </Text>
            {createLinks(prop.views)}
          </Box>
        )
      }
      return (
        <NavLink to={prop.layout + prop.path} key={key}>
          {activeRoute(prop.layout + prop.path) === 'active' ? (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              alignItems='center'
              boxShadow={sidebarActiveShadow}
              bg={activeBg}
              transition={variantChange}
              mb={{
                xl: '6px'
              }}
              mx={{
                xl: 'auto'
              }}
              ps={{
                sm: '10px',
                xl: '16px'
              }}
              py='12px'
              borderRadius='15px'
              _hover={{}}
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.04)'
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    color='blue.500'
                    h='30px'
                    w='30px'
                    me='12px'
                    transition={variantChange}
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my='auto' fontSize='sm'>
                  {t(prop.sideBar)}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              alignItems='center'
              bg='transparent'
              mb={{
                xl: '6px'
              }}
              mx={{
                xl: 'auto'
              }}
              py='12px'
              ps={{
                sm: '10px',
                xl: '16px'
              }}
              borderRadius='15px'
              _hover={{}}
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: 'none'
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={inactiveBg}
                    color='blue.500'
                    h='30px'
                    w='30px'
                    me='12px'
                    transition={variantChange}
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my='auto' fontSize='sm'>
                  {t(prop.sideBar)}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      )
    })
  }
  const { logo, routes } = props

  var links = <>{createLinks(routes)}</>
  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = useColorModeValue('white', 'navy.800')
  let inactiveBg = useColorModeValue('white', 'navy.700')
  let sidebarRadius = '20px'
  let sidebarMargins = '0px'
  var brand = (
    <Box pt={'25px'} mb='12px' textAlign='center' fontSize='17px'>
      {/* {logo} */}
      <Text>
        受講管理システム
      </Text>
      <HSeparator my='26px' />
    </Box>
  )
  let inactiveColor = useColorModeValue('gray.400', 'gray.400')

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: 'block' }} position='fixed'>
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w='180px'
          maxW='180px'
          ms={{
            sm: '16px'
          }}
          my={{
            sm: '40px'
          }}
          h='550px'
          ps='10px'
          pe='10px'
          m={sidebarMargins}
          filter='drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.05))'
          borderRadius={sidebarRadius}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={
              document.documentElement.dir === 'rtl'
                ? renderTrackRTL
                : renderTrack
            }
            renderThumbVertical={useColorModeValue(
              renderThumbLight,
              renderThumbDark
            )}
            renderView={
              document.documentElement.dir === 'rtl'
                ? renderViewRTL
                : renderView
            }
          >
            <Box>{brand}</Box>
            <Stack direction='column' mb='40px'>
              <Box>{links}</Box>
              <Box>
                <Button
                  boxSize='initial'
                  justifyContent='flex-start'
                  alignItems='center'
                  bg='transparent'
                  mb={{
                    xl: '6px'
                  }}
                  mx={{
                    xl: 'auto'
                  }}
                  py='12px'
                  ps={{
                    sm: '10px',
                    xl: '16px'
                  }}
                  borderRadius='15px'
                  _hover={{}}
                  w='100%'
                  _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                  }}
                  _focus={{
                    boxShadow: 'none'
                  }}
                  onClick={async () => {
                    await removeCookie(STORAGE.ACCESS_TOKEN, { path: '/' })
                    window.location.href = '/auth/login'
                  }}
                >
                  <Flex>
                    <IconBox
                      bg={inactiveBg}
                      color='blue.500'
                      h='30px'
                      w='30px'
                      me='12px'
                    >
                      <Image boxSize="32px" src={iconLogOut} alt="Log out" />
                    </IconBox>
                    <Text color={inactiveColor} my='auto' fontSize='sm'>
                      {t('logout')}
                    </Text>
                  </Flex>
                </Button>
              </Box>
            </Stack>
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  )
}

// SIDEBAR RESPONSIVE

export function SidebarResponsive(props) {
  // to check for active links and opened collapses
  let location = useLocation()
  const { logo, routes, colorMode, hamburgerColor, ...rest } = props

  // this is for the rest of the collapses
  const [state, setState] = React.useState({})
  const mainPanel = React.useRef()
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : ''
  }
  // Chakra Color Mode
  let activeBg = useColorModeValue('white', 'navy.700')
  let inactiveBg = useColorModeValue('white', 'navy.700')
  let activeColor = useColorModeValue('gray.700', 'white')
  let inactiveColor = useColorModeValue('gray.400', 'white')
  let sidebarActiveShadow = useColorModeValue(
    '0px 7px 11px rgba(0, 0, 0, 0.04)',
    'none'
  )
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800')

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect || prop.hideMenu) {
        return null
      }
      if (prop.category) {
        var st = {}
        st[prop['state']] = !state[prop.state]
        return (
          <>
            <Text
              color={activeColor}
              fontWeight='bold'
              mb={{
                xl: '6px'
              }}
              mx='auto'
              ps={{
                sm: '10px',
                xl: '16px'
              }}
              py='12px'
            >
              {prop.sideBar}
            </Text>
            {createLinks(prop.views)}
          </>
        )
      }
      return (
        <NavLink to={prop.layout + prop.path} key={key}>
          {activeRoute(prop.layout + prop.path) === 'active' ? (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              alignItems='center'
              bg={activeBg}
              boxShadow={sidebarActiveShadow}
              mb={{
                xl: '6px'
              }}
              mx={{
                xl: 'auto'
              }}
              ps={{
                sm: '10px',
                xl: '16px'
              }}
              py='12px'
              borderRadius='15px'
              _hover={{}}
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: 'none'
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg='blue.500'
                    color='white'
                    h='30px'
                    w='30px'
                    me='12px'
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={activeColor} my='auto' fontSize='sm'>
                  {t(prop.name)}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize='initial'
              justifyContent='flex-start'
              alignItems='center'
              bg='transparent'
              mb={{
                xl: '6px'
              }}
              mx={{
                xl: 'auto'
              }}
              py='12px'
              ps={{
                sm: '10px',
                xl: '16px'
              }}
              borderRadius='15px'
              _hover={{}}
              w='100%'
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: 'none'
              }}
            >
              <Flex>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <IconBox
                    bg={inactiveBg}
                    color='blue.500'
                    h='30px'
                    w='30px'
                    me='12px'
                  >
                    {prop.icon}
                  </IconBox>
                )}
                <Text color={inactiveColor} my='auto' fontSize='sm'>
                  {t(prop.name)}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      )
    })
  }

  var links = <>{createLinks(routes)}</>

  //  BRAND

  var brand = (
    <Box pt={'35px'} mb='8px'>
      {logo}
      <HSeparator my='26px' />
    </Box>
  )

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
  // Color variables
  return (
    <Flex
      display={{ sm: 'none' }}
      ref={mainPanel}
      alignItems='center'
    >
      <HamburgerIcon
        color={hamburgerColor}
        w='18px'
        h='18px'
        ref={btnRef}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === 'rtl' ? 'right' : 'left'}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent
          w='180px'
          maxW='180px'
          ms={{
            sm: '10px'
          }}
          my={{
            sm: '10px'
          }}
          borderRadius='16px'
          bg={sidebarBackgroundColor}
        >
          <DrawerCloseButton
            _focus={{ boxShadow: 'none' }}
            _hover={{ boxShadow: 'none' }}
          />
          <DrawerBody maxW='250px' px='1rem'>
            <Box maxW='100%' h='90vh'>
              {/*<Box>{brand}</Box>*/}
              <Box pt={'25px'} mb='12px' textAlign='center' fontSize='17px'>
                {/* {logo} */}
                <Text>
                  受講管理システム
                </Text>
                <HSeparator my='26px' />
              </Box>
              <Stack direction='column' mb='40px'>
                <Box>{links}</Box>
                <Box>
                  <Button
                    boxSize='initial'
                    justifyContent='flex-start'
                    alignItems='center'
                    bg='transparent'
                    mb={{
                      xl: '6px'
                    }}
                    mx={{
                      xl: 'auto'
                    }}
                    py='12px'
                    ps={{
                      sm: '10px',
                      xl: '16px'
                    }}
                    borderRadius='15px'
                    _hover={{}}
                    w='100%'
                    _active={{
                      bg: 'inherit',
                      transform: 'none',
                      borderColor: 'transparent'
                    }}
                    _focus={{
                      boxShadow: 'none'
                    }}
                    onClick={async () => {
                      await removeCookie(STORAGE.ACCESS_TOKEN, { path: '/' })
                      window.location.href = '/auth/login'
                    }}
                  >
                    <Flex>
                      <IconBox
                        bg={inactiveBg}
                        color='blue.500'
                        h='30px'
                        w='30px'
                        me='12px'
                      >
                        <ArrowBackIcon />
                      </IconBox>
                      <Text color={inactiveColor} my='auto' fontSize='sm'>
                        {t('logout')}
                      </Text>
                    </Flex>
                  </Button>
                </Box>
              </Stack>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}

export default Sidebar
